const session = {
    not_found: '未找到会话。请返回并重新登录。',
    invalid_credentials: '账号或密码错误，请重新输入。',
    invalid_sign_in_method: '当前登录方式不可用',
    invalid_connector_id: '找不到 ID 为 {{connectorId}} 的可用连接器。',
    insufficient_info: '登录信息缺失，请检查你的输入。',
    connector_id_mismatch: '传入的连接器 ID 与 session 中保存的记录不一致',
    connector_session_not_found: '无法找到连接器登录信息，请尝试重新登录。',
    verification_session_not_found: '验证失败，请重新验证。',
    verification_expired: '当前页面已超时。为确保你的账号安全，请重新验证。',
    verification_blocked_too_many_attempts: '尝试次数过多。请稍后再试 {{relativeTime}}。',
    unauthorized: '请先登录',
    unsupported_prompt_name: '不支持的 prompt name',
    forgot_password_not_enabled: '忘记密码功能没有开启。',
    verification_failed: '验证失败，请重新验证。',
    connector_validation_session_not_found: '找不到连接器用于验证 token 的信息。',
    csrf_token_mismatch: 'CSRF token 不匹配。',
    identifier_not_found: '找不到用户标识符。请返回并重新登录。',
    interaction_not_found: '找不到交互会话。请返回并重新开始会话。',
    not_supported_for_forgot_password: '此操作不支持忘记密码。',
    identity_conflict: '检测到身份不匹配。请启动一个新会话以使用不同的身份进行操作。',
    mfa: {
        require_mfa_verification: '需要多因素身份验证 (MFA) 才能登录。',
        mfa_sign_in_only: '多因素身份验证 (MFA) 仅适用于登录交互。',
        pending_info_not_found: '找不到待处理的多因素身份验证 (MFA) 信息，请先启动 MFA。',
        invalid_totp_code: '无效的 TOTP 代码。',
        webauthn_verification_failed: 'WebAuthn 验证失败。',
        webauthn_verification_not_found: '未找到 WebAuthn 验证。',
        bind_mfa_existed: '多因素身份验证 (MFA) 已存在。',
        backup_code_can_not_be_alone: '备用代码不能是唯一的 MFA。',
        backup_code_required: '需要备用代码。',
        invalid_backup_code: '无效的备用代码。',
        mfa_policy_not_user_controlled: 'MFA 策略不受用户控制。',
    },
    sso_enabled: '该邮箱已开启单点登录，请使用 SSO 登录。',
};
export default Object.freeze(session);
