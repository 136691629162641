const menu = {
    profile: '帐户管理',
    language: '语言',
    appearance: {
        label: '主题',
        light: '浅色模式',
        dark: '深色模式',
        system: '跟随系统',
    },
    sign_out: '退出登录',
};
export default Object.freeze(menu);
