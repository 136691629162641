const get_started = {
    page_title: '开始上手',
    title: '成功开发身份方案，我们先来探索一番',
    subtitle: '一些你可以做的事情，以快速获取 Logto 的价值',
    develop: {
        title: '开发：花 5 分钟集成你的应用',
        title_cloud: 'Develop: 安全地在几分钟内集成您的应用',
        subtitle_cloud: '或花 5 分钟用我们预构建的 SDK 和教程集成您的应用。',
    },
    customize: {
        title: '自定义：提供出色的登录体验',
        preview: {
            title: '实时预览定制的登录体验',
            subtitle: '立刻尝试 Logto 登录体验，看看它是如何工作的',
        },
        connector: {
            title: '添加更多连接器来支持更多社交登录方式',
            subtitle: '尝试免密码登录，为您的客户提供安全、无摩擦的体验',
        },
        continue_customizing: '继续定制',
        try_now: '立即尝试',
        add_more: '添加更多',
    },
    secure: {
        title: '安全：保护你的资源',
    },
    manage: {
        title: '管理：为你的产品和用户定义访问控制',
        rbac: {
            title: '为你的资源添加基于角色的访问控制',
            subtitle: '通过可扩展的角色授权控制你的资源，应对不同的使用场景。',
        },
        create_roles: '创建角色',
    },
    view_all: '查看全部 →',
};
export default Object.freeze(get_started);
