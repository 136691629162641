const tabs = {
    get_started: '开始上手',
    dashboard: '仪表盘',
    applications: '全部应用',
    api_resources: 'API 资源',
    sign_in_experience: '登录体验',
    connectors: '连接器',
    enterprise_sso: '企业SSO',
    webhooks: 'Webhooks',
    organizations: '组织',
    users: '用户管理',
    audit_logs: '审计日志',
    roles: '角色',
    docs: '文档',
    tenant_settings: '租户设置',
    mfa: '多因素认证',
    customize_jwt: '自定义 JWT',
    signing_keys: '签名密钥',
    organization_template: '组织模板',
};
export default Object.freeze(tabs);
