const verification_code = {
    phone_email_empty: '手机号与邮箱地址均为空',
    not_found: '验证码不存在，请先请求发送验证码',
    phone_mismatch: '手机号码不匹配，请尝试请求新的验证码。',
    email_mismatch: '邮箱地址不匹配，请尝试请求新的验证码。',
    code_mismatch: '验证码不正确',
    expired: '验证码已过期，请尝试请求新的验证码。',
    exceed_max_try: '超过最大验证次数，请尝试请求新的验证码。',
};
export default Object.freeze(verification_code);
