const permissions = {
    search_placeholder: '通过 API 或权限名称搜索',
    search_placeholder_without_api: '通过权限名称搜索',
    name_column: '权限',
    description_column: '描述',
    api_column: 'API',
    placeholder_title: '权限',
    placeholder_description: '权限是指访问资源的授权（我们称其为 API 资源）。',
    edit: '编辑权限',
    delete: '删除权限',
    remove: '移除权限',
    edit_title: '编辑 API 权限',
};
export default Object.freeze(permissions);
